import React, { useContext } from "react";
import { graphql } from "gatsby";
import tw, { styled, css, GlobalStyles } from "twin.macro";
import Header from "../components/dynamic-components/header";
import BottomNavigation from "../components/dynamic-components/bottom-navigation";
import Footer from "../components/dynamic-components/footer";
import GeneralFeedback from "../components/dynamic-components/general-feedback";
import { TransitionState } from "gatsby-plugin-transition-link";
import { m, LazyMotion } from "framer-motion";
import { useDispatch } from "react-redux";
import { selectCategory } from "../store/actions/resources";
import { Link } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";
import { ThemeContext } from "../components/themes/theme-context";
import { useIntl } from "gatsby-plugin-intl";
import SiteMetadata from "../components/site-metadata";


const InnerContainer = tw.div`max-w-screen-xl mx-auto`;

const StyledSection = tw.section`flex flex-col justify-center text-center px-10 min-h-screen items-center`;

const StyledHeadline = tw.h1`font-serif font-normal text-2xl leading-serif-2xl mb-4 px-12 md:text-3xl md:leading-serif-3xl lg:text-4xl lg:leading-serif-4xl lg:mb-6`;

const StyledSubheadline = tw.p`font-sans font-normal text-base leading-sans-base mb-8 md:text-lg md:leading-sans-lg lg:text-xl lg:leading-sans-xl md:px-16 xl:px-32`;

// const StyledLanguageNav = tw.div`lg:hidden`;

const StyledButtonContainer = tw.div`flex flex-col justify-center items-center w-full md:w-7/12 lg:w-1/2 xl:w-1/3`;

const StyledButton = styled(Link)`
	${tw`my-2 font-medium font-sans text-base leading-sans-base md:text-lg md:leading-sans-lg md:my-3 lg:text-xl lg:leading-sans-xl lg:my-4`}
`;

const NeedHelpButton = tw(
	StyledButton
)`text-white rounded-full w-full py-5 shadow-help-options md:py-6 md:px-16 transition duration-150 ease-linear transform hover:scale-105`;

const loadFeatures = () =>
	import("../components/dynamic-components/features").then(
		(res) => res.default
	);

const Homepage = ({ pageContext, data }) => {
	const node = data.allContentfulHomePage.nodes[0];
	const headline = node.headline;
	const titleBarText = node.titleBarText;
	const subheadline = node.subheadline.subheadline;
	const helpOptions = node.helpOptions;
	const needHelpData = helpOptions[0];
	const seekingHelpData = helpOptions[1];
	const skipData = helpOptions[2];
	const intl = useIntl();

	const generalFeedbackData = data.allContentfulFeedbackComponent.nodes.filter(
		(item) => item.title === "General Feedback"
	)[0];

	const sectionAnimation = {
		hidden: { opacity: 0, y: 50 },
		visible: { opacity: 1, y: 0 },
	};

	const dispatch = useDispatch();
	const { theme } = useContext(ThemeContext);

	const resetCategory = () => {
		dispatch(selectCategory(null));
	};

	return (
		<>
			<Helmet
				title={`${titleBarText}`}
				htmlAttributes={{ title: `${titleBarText}`, lang: `${intl.locale}` }}
			/>
			<SiteMetadata data={data.allContentfulSiteMetadata} />
			<Header data={data.allContentfulHeader} />
			<InnerContainer>
				<TransitionState>
					{({ mount }) => {
						return (
							<LazyMotion features={loadFeatures}>
								<m.div
									initial="hidden"
									animate={mount ? "visible" : ""}
									variants={sectionAnimation}
									transition={{ duration: 0.8 }}
								>
									<StyledSection
										css={css`
											@media (max-height: 600px) {
												justify-content: flex-start;
											}
										`}
									>
										<StyledHeadline>{headline}</StyledHeadline>
										<StyledSubheadline>{subheadline}</StyledSubheadline>
										<StyledButtonContainer>
											<NeedHelpButton
												to={`/${seekingHelpData.linkTo.slug}/`}
												onClick={resetCategory}
												css={theme.buttonLight}
											>
												{needHelpData.label}
											</NeedHelpButton>
										</StyledButtonContainer>
									</StyledSection>
								</m.div>
							</LazyMotion>
						);
					}}
				</TransitionState>
			</InnerContainer>
			<Footer data={data.allContentfulFooter} />
			<BottomNavigation
				data={data.allContentfulBottomNavigation}
				languageData={data.allContentfulLanguageNavigation}
			/>
			<GeneralFeedback data={generalFeedbackData} />
			<GlobalStyles />
		</>
	);
};

export const query = graphql`
	query HomepageQuery($locale: String!) {
		allContentfulHomePage(filter: { node_locale: { eq: $locale } }) {
			nodes {
				titleBarText
				headline
				subheadline {
					subheadline
				}
				helpOptions {
					id
					title
					label
					linkTo {
						id
						title
						slug
					}
				}
			}
		}
		allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...footer
			}
		}
		allContentfulHeader(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...header
			}
		}
		allContentfulBottomNavigation(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...bottomNavigation
			}
		}
		allContentfulFeedbackComponent(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...feedbackComponent
			}
		}
		allContentfulLanguageNavigation(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...languageNavigation
			}
		}
		allContentfulSiteMetadata(filter: { node_locale: { eq: $locale } }) {
			nodes {
				...siteMetadata
			}
		}
	}
`;

export default Homepage;
